import IfsService from "@/services/ifs.service";
import getCookie from "@/lib/get-cookie";
import setCookie from "@/lib/set-cookie";
import deleteCookie from "@/lib/delete-cookie";
import i18n from '@/i18n';

const user = getCookie('user');

const initialState = {
    user: user ? user : null,
    projects: [],
    projectsCount: 0,
    statistic: {},
    currentProject: {},
    provisionalProject: {}
}

export const IfsModule = {
    namespaced: true,
    state: initialState,
    actions: {
        signIn({ commit }, params) {
            return new Promise((resolve, reject) => {
                IfsService.signIn(params.username, params.password)
                    .then(user => {
                        if (user) {
                            setCookie('user', JSON.stringify(user), {expires: 86400});
                        }
                        commit('signIn', user);
                        resolve(user);
                    })
                    .catch(error => {
                        commit('signOut');
                        reject(error);
                    });
            });
        },
        signOut({ commit }) {
            return new Promise(resolve => {
                deleteCookie('user');
                commit('signOut');
                resolve();
            });
        },
        listProjects({ commit }, payload) {
            return new Promise((resolve, reject) => {
                IfsService.listProjects(payload)
                    .then(response => {
                        commit('populateProjects', response.data);
                        commit('populateStatistic', response.statistic);
                        commit('populateProjectsCount', response.countAll);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        getProject({ commit }, payload) {
            return new Promise((resolve, reject) => {
                IfsService.getProject(payload)
                    .then(project => {
                        commit('populateProject', project);
                        resolve(project);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        createProject({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                IfsService.createProject(payload)
                    .then(async project => {
                        dispatch('toast/success', {
                            title: i18n.t('success'),
                            message: i18n.t('toast.message.success.createProject')
                        }, { root: true })
                        resolve(project);
                    })
                    .catch(async error => {
                        await this.$store.dispatch('toast/danger', {
                            title: i18n.t('error'),
                            message: i18n.t('toast.message.error.unknown')
                        }, { root: true })
                        reject(error);
                    })
            });
        },
        updateProject({ commit }, payload) {
            return new Promise((resolve, reject) => {
                IfsService.updateProject(payload)
                    .then(project => {
                        commit('updateProject', project);
                        resolve(project);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        createEnergyCertificate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                IfsService.createEnergyCertificate(payload)
                    .then(project => {
                        commit('updateProject', project);
                        resolve(project);
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        sendToCustomer({ dispatch}, payload) {
            return new Promise((resolve, reject) => {
                IfsService.sendToCustomer(payload)
                    .then(() => {
                        dispatch('toast/success', {
                            title: i18n.t('success'),
                            message: i18n.t('toast.message.success.sendEnergyCertificate'),
                        }, { root: true })
                        resolve();
                    })
                    .catch(error => {
                        dispatch('toast/danger', {
                            title: i18n.t('toast.title.error.general'),
                            message: i18n.t('toast.message.error.sendEnergyCertificate')
                        }, { root: true })
                        reject(error);
                    })
            });
        }
    },
    mutations: {
        signIn(state, user) {
            state.user = user;
        },
        signOut(state) {
            state.user = null;
        },
        populateProjectsCount(state, count) {
            state.projectsCount = count;
        },
        populateStatistic(state, statistic) {
            state.statistic = statistic
        },
        populateProjects(state, projects) {
            state.projects = projects
        },
        populateProject(state, project) {
            let currentProject = JSON.parse(JSON.stringify(project.history.latest).replace(/null/gi, "\"\""));
            let provisionalProject = JSON.parse(JSON.stringify(project.data).replace(/null/gi, "\"\""));

            state.currentProject = currentProject;
            state.provisionalProject = provisionalProject;
        },
        updateProject(state, project) {
            state.currentProject = JSON.parse(JSON.stringify(project.data).replace(/null/gi, "\"\""));
        }
    }
}