import moment from "moment";

export default function getInfoObject(payload) {
    return {
        header: {
            id: "",
            parent_id: "",
            portfolio_element: process.env.VUE_APP_SERVICE_PORTFOLIO_ELEMENT,
            client: "",
            node: "",
            version: process.env.VUE_APP_SERVICE_INFO_OBJECT_VERSION,
            type: "SINGLE",
            status: "NEW",
            encryption_level: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_LEVEL,
            encryption_key: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_KEY,
            process_history: [
                {
                    step: "1",
                    timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                    unit: "",
                    type: "SERVICE_REQUEST",
                    status: "NEW",
                    status_comment: ""
                }
            ]
        },
        container: [
            {
                header: {
                    id: "",
                    parent_id: "",
                    portfolio_element: process.env.VUE_APP_SERVICE_PORTFOLIO_ELEMENT,
                    client: "",
                    node: "",
                    version: process.env.VUE_APP_SERVICE_INFO_OBJECT_VERSION,
                    type: "SERVICE_REQUEST",
                    status: "NEW",
                    encryption_level: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_LEVEL,
                    encryption_key: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_KEY
                },
                payload: payload
            }
        ]
    };
}