import { v4 as uuidv4 } from 'uuid';

export const ToastModule = {
    namespaced: true,
    state: {
        toast: []
    },
    actions: {
        success({ commit }, payload) {
            commit('add', {
                type: 'success',
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4()
            });
        },
        danger({ commit }, payload) {
            commit('add', {
                type: 'danger',
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4()
            });
        },
        warning({ commit }, payload) {
            commit('add', {
                type: 'warning',
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4()
            });
        },
        info({ commit }, payload) {
            commit('add', {
                type: 'info',
                title: payload.title,
                message: payload.message,
                duration: payload.duration,
                id: uuidv4()
            });
        }
    },
    mutations: {
        add(state, toast) {
            state.toast.push(toast);
            if (!toast.duration) {
                toast.duration = 3000
            }

            if (toast.duration !== -1) {
                setTimeout(
                    function() {
                        const index = state.toast.findIndex(x => x.id === toast.id);
                        state.toast.splice(index, 1);
                    },
                    toast.duration
                );
            }
        },
        remove(state, id) {
            const index = state.toast.findIndex(x => x.id === id);
            state.toast.splice(index, 1);
        }
    }
}