import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/scss/theme.scss';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import i18n from '@/i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import Gravatar from 'vue-gravatar';
import moment from "moment";
import Vuelidate from 'vuelidate';
import {
  faLanguage,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPen,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faCaretUp,
  faTimes,
  faHome,
  faList,
  faPlus,
  faPencilAlt,
  faCircle,
  faPaperclip,
  faFaucet,
  faThermometerThreeQuarters,
  faCompass,
  faRulerCombined,
  faHouseUser,
  faExclamation,
  faSignInAlt,
  faSave,
  faTrash,
  faUpload,
  faEnvelope,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faQuestionCircle
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faLanguage,
  faBell,
  faQuestionCircle,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPen,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faCaretUp,
  faTimes,
  faHome,
  faList,
  faPlus,
  faPencilAlt,
  faCircle,
  faPaperclip,
  faFaucet,
  faThermometerThreeQuarters,
  faCompass,
  faRulerCombined,
  faHouseUser,
  faExclamation,
  faSignInAlt,
  faSave,
  faTrash,
  faUpload,
  faEnvelope,
  faInfoCircle
);

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-gravatar', Gravatar);

Vue.filter('timestamp', date => moment(date).format('DD.MM.YYYY, HH:MM'));

Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    const html = document.documentElement;
    html.setAttribute('lang', i18n.locale.substring(0, 2));
    window.addEventListener("resize", this.setSidebar);
    this.setSidebar();
  },
  methods: {
    setSidebar() {
      if (window.innerWidth < 768) {
        this.$store.commit('sidebar/closeSidebar', '0px')
      } else if (window.innerWidth < 1200) {
        this.$store.commit('sidebar/closeSidebar', '45px')
      } else {
        this.$store.commit('sidebar/openSidebar', '224px')
      }
    }
  }
}).$mount('#app')
