import { IfsModule } from "@/modules/ifs.module";

export default function getRequestHeaders() {
    let headers = {
        'Content-Type': 'application/json',
    };

    if (IfsModule.state.user && IfsModule.state.user.token) {
        headers['Authorization'] = 'Bearer ' + IfsModule.state.user.token;
    }

    return headers;
}