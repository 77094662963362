import Vue from "vue";
import Vuex from "vuex";
import { IfsModule } from "@/modules/ifs.module";
import { SidebarModule } from "@/modules/sidebar.module"
import { ToastModule } from "@/modules/toast.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ifs: IfsModule,
    sidebar: SidebarModule,
    toast: ToastModule
  }
})
