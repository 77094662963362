import i18n from '@/i18n';

const initialState = {
    open: true,
    width: '224px',
    content: [
        {
            name: i18n.tc('dashboard', 1),
            route: 'dashboard',
            icon: 'home'
        },
        {
            name: i18n.tc('project', 2),
            route: 'projects',
            icon: 'list'
        },
        {
            name: i18n.t('enums.projectStatusDescShort.PROJECT_NEW'),
            route: 'new-project',
            icon: 'plus'
        },
    ]
}

export const SidebarModule = {
    namespaced: true,
    state: initialState,
    mutations: {
        toggleSidebar(state) {
            state.open = !state.open;
            if (state.width === '224px') {
                state.width = '45px';
            } else {
                state.width = '224px'
            }
        },
        openSidebar(state, width) {
            state.open = true;
            state.width = width;
        },
        closeSidebar(state, width) {
            state.open = false;
            state.width = width;
        },
        toggleGroup(state, index) {
            state.content[index].collapsed = !state.content[index].collapsed;
        }
    }
}