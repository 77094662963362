import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import i18n from '@/i18n';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: i18n.t('home')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/PageProfile'),
    meta: {
      requiresAuth: true,
      title: i18n.t('profile')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../pages/PageDashboard'),
    meta: {
      requiresAuth: true,
      title: i18n.t('dashboard')
    }
  },
  {
    path: '/projects/:projectNumber/:projectPin',
    name: 'edit-project',
    component: () => import(/* webpackChunkName: "edit-project" */ '../pages/PageEditProject'),
    meta: {
      requiresAuth: true,
      title: i18n.t('editProject')
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../pages/PageProjects'),
    meta: {
      requiresAuth: true,
      title: i18n.tc('project', 2)
    }
  },
  {
    path: '/new-project',
    name: 'new-project',
    component: () => import(/* webpackChunkName: "new-project" */ '../pages/PageNewProject'),
    meta: {
      requiresAuth: true,
      title: i18n.t('newProject')
    }
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "sign-in" */ '../pages/PageSignIn'),
    meta: {
      requiresAuth: false,
      title: i18n.t('sign-in')
    }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../pages/PagePasswordReset'),
    meta: {
      requiresAuth: false,
      title: i18n.t('reset-password')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.ifs.user !== null;

  // Redirect to the login page if not logged in and the route requires auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (loggedIn === false) {
      next({ name: 'sign-in' });
    }
  }

  // Redirect all HOME links to the Dashboard Overview
  if (to.matched.some(record => record.name === 'home')) {
    next({ name: 'dashboard' });
  }

  // Always call next()
  next();
});

router.afterEach((to) => {
  let title = process.env.VUE_APP_TITLE;

  Vue.nextTick(() => {
    if (to.meta.title) {
      title = to.meta.title + ' | ' + title;
    }

    document.title = title;
  });
});