/**
 * Returns a cookie by its name
 *
 * @param {string}  name Name of the cookie
 * @param {boolean} json JSON.parse() the output?
 * @returns {string|boolean|any}
 */
export default function getCookie(name, json = true) {
    name += "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i <ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            const cav = c.substring(name.length, c.length);

            if (json === true) {
                return JSON.parse(cav);
            }

            return cav;
        }
    }

    return false;
}