import Vue from "vue";
import VueI18n from "vue-i18n";
import en_US from "@/i18n/en-US.json";
import de_DE from "@/i18n/de-DE.json";

Vue.use(VueI18n);

const messages = {
    "en-US": {
        ...en_US
    },
    "de-DE": {
        ...de_DE
    }
};

export default new VueI18n({
    locale: "de-DE",
    messages
});