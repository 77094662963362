export default function getUrl(type) {
    let url = process.env.VUE_APP_SERVICE_URL + '/' + process.env.VUE_APP_SERVICE_PORTFOLIO_ELEMENT + '/' + type + '?';
    let params = new URLSearchParams({
        Key: process.env.VUE_APP_SERVICE_KEY,
        Id: process.env.VUE_APP_SERVICE_ID,
        Ver: process.env.VUE_APP_SERVICE_INFO_OBJECT_VERSION,
        EnL: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_LEVEL,
        EnK: process.env.VUE_APP_SERVICE_INFO_OBJECT_ENCRYPTION_KEY
    });
    return url + params.toString();
}