import axios from 'axios';
import getUrl from '@/lib/get-url';
import getInfoObject from '@/lib/get-info-object';
import getRequestHeaders from '@/lib/get-request-headers';

class IfsService {
    signIn(username, password) {
        return new Promise((resolve, reject) => {
            const requestData = { username, password };

            axios.post(getUrl('login'), requestData)
                .then(response => {
                    let user = null;

                    if (response.status === 200) {
                        user = {
                            username: response.data.data.username,
                            token: response.data.data.token
                        }
                    }
                    resolve(user);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        });
    }

    listProjects(payload) {
        return new Promise((resolve, reject) => {

            let requestData = {
                event: 'PROJECT_LIST_PROJECT_ADMIN',
                paginationPage: payload.paginationPage,
                paginationPageSize: payload.paginationPageSize
            }

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(requestData), requestConfig)
                .then(response => {
                    let projects;

                    if (response.status === 200) {
                        projects = response.data.container[0].payload;
                    }
                    resolve(projects);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        });
    }

    getProject(payload) {
        return new Promise((resolve, reject) => {

            let requestData = {
                event: 'PROJECT_GET_PROJECT',
                projectNumber: payload.projectNumber,
                projectPin: payload.projectPin
            }

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(requestData), requestConfig)
                .then(response => {
                    let project;

                    if (response.status === 200) {
                        project = response.data.container[0].payload;
                    }
                    resolve(project);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    createProject(payload) {
        return new Promise((resolve, reject) => {
            let requestData = payload;
            requestData.event = 'PROJECT_CREATE_PROJECT_ADMIN';
            requestData.product = 'ENERGY_CERTIFICATE_APARTMENT';

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(requestData), requestConfig)
                .then(response => {
                    let project;

                    if (response.status === 200) {
                        project = response.data.container[0].payload;
                    }
                    resolve(project);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    updateProject(payload) {
        return new Promise((resolve, reject) => {
            let requestData = payload;
            requestData.event = 'PROJECT_UPDATE_PROJECT_ADMIN';

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(requestData), requestConfig)
                .then(response => {
                    let project;

                    if (response.status === 200) {
                        project = response.data.container[0].payload;
                    }
                    resolve(project);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    createEnergyCertificate(payload) {
        return new Promise((resolve, reject) => {

            let requestData = {
                event: "DOCUMENT_CREATE_ENERGY_CERTIFICATE",
                documentType: payload.documentType,
                projectNumber: payload.projectNumber,
                projectPin: payload.projectPin
            }

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(requestData), requestConfig)
                .then(response => {
                    let project;

                    if (response.status === 200) {
                        project = response.data.container[0].payload;
                    }
                    resolve(project);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    sendToCustomer(payload) {
        return new Promise((resolve, reject) => {

            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(getUrl('info'), getInfoObject(payload), requestConfig)
                .then(response => {
                    let project;

                    if (response.status === 200) {
                        project = response.data.container[0].payload;
                    }
                    resolve(project);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }
}

export default new IfsService();